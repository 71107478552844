import CheckRole from "./CheckRole";
import { useEffect, useState } from "react";
import Unauthorized from "../roles/Unauthorized";
import { useSetUser, useUser } from "./UserContext";
import LoadingScreen from "../components/global/LoadingScreen";
import InternalServerError from "../components/global/InternalServerError";
import { useUserSettings } from "../components/context/UserSettingsContext";
import { useLazyGetUserByEmailV1Query, useLazyGetUserRolesV1Query, useLazyGetUserSettingsQuery, } from "../store/penpalServerAPI";

const CheckAuthorized = ({ email = "" }) => {
  const user = useUser();
  const updateUser = useSetUser();
  const [myRoles, setMyRoles] = useState([]);
  const { rolePref, setRolePref } = useUserSettings();
  const [authLoading, setAuthLoading] = useState(true);

  const [getUserByEmail, { data, isError, isSuccess, error, isLoading }] =
    useLazyGetUserByEmailV1Query();

  const [ getUserSettings, {
    data: userSettingsData,
    isLoading: userSettingsIsLoading,
    isSuccess: userSettingsIsSuccess,
    isError: userSettingsIsError,
    error: userSettingsError
  }] = useLazyGetUserSettingsQuery();

  useEffect(() => {
    if (isError) {
      console.log("Error while fetching GET UserByEmail: ", error);
    }
  }, [isError, error]);

  const [
    getUserRoles,
    {
      data: rolesData,
      isError: rolesIsError,
      error: rolesError,
      isSuccess: rolesIsSuccess,
      isLoading: rolesIsLoading,
    },
  ] = useLazyGetUserRolesV1Query();

  useEffect(() => {
    if (rolesIsError) {
      console.log("Error while fetching GET UserRoles: ", rolesError);
    }
  }, [rolesIsError, rolesError]);

  // Fetch user data by email
  useEffect(() => {
    if (email.length) {
      getUserByEmail(email);
    }
  }, [email]);

  // Fetch user roles based on user data
  useEffect(() => {
    if (isSuccess && Object.keys(data).length > 0) {
      getUserSettings(data.userId);
      getUserRoles(data.userId);
    }
  }, [data, isSuccess]);

  // Process user roles data
  useEffect(() => {
    if (rolesIsSuccess) {

      if (rolesData.length > 0) {
        setMyRoles(rolesData.map((obj) => obj.roleName));
      } else {
        setMyRoles(["NONE"]);
      }

      setAuthLoading(false); // Finished loading authorization state
    } else if (rolesIsError) {
      console.error("ERROR: Could not retrieve User Roles.");
      setAuthLoading(false);
    }
  }, [rolesData, rolesIsSuccess, rolesIsError]);

  // Update user context with authorization and role data
  useEffect(() => {
    if (rolesIsSuccess && data && userSettingsIsSuccess) {

      const myRole =
        userSettingsData && myRoles.includes(rolePref)
          ? rolePref
          : myRoles[0] === "NONE"
            ? "NONE"
            : myRoles[0];

      if (!userSettingsData && myRoles[0] !== "NONE") {
        setRolePref(myRoles[0]);
      }

      updateUser({
        UserID: data.userId,
        Email: data.email,
        Phone: data.phone,
        FirstName: data.firstName,
        LastName: data.lastName,
        SelectedRole: myRole,
        Roles: myRoles,
        Authorized: data.authorized,
        DateTimeCreated: data.dateTimeCreated,
      });
    }
  }, [rolesIsSuccess, data, rolePref, userSettingsIsSuccess]);

  // Update selected role in user context if rolePref changes
  // useEffect(() => {
  //   updateUser((user) => ({
  //     ...user,
  //     SelectedRole: rolePref,
  //   }));
  // }, [rolePref]);

  if (error?.status === 500 || rolesError?.status === 500)
    return <InternalServerError />;
  if (isError || rolesError) return <Unauthorized />;
  if (authLoading || isLoading || rolesIsLoading)
    return <LoadingScreen screenHeight={true} />;
  if (user.Authorized) return <CheckRole />;
};

export default CheckAuthorized;
