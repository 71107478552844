import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { setAuthToken } from "./authSlice";

let veryBasedUrl;

if (process.env.NODE_ENV === 'development') veryBasedUrl = 'https://localhost:7193/'
else veryBasedUrl = 'https://server.penpal.day/';

export const penpalServerApi = createApi({
  reducerPath: "penpalServerAPI",
  baseQuery: retry(fetchBaseQuery({
    baseUrl: veryBasedUrl,
    mode: "cors",
    prepareHeaders: (headers, { getState }) => {
      headers.set("Access-Control-Request-Headers", `authorization`);
      headers.set("Content-Type", "application/json");
      const token = getState().auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }), { maxRetries: 2 }),
  tagTypes: [
    "admin-protocols",
    "all-buildings-rooms",
    "all-devices",
    "all-user-locations",
    "auth-requests",
    "battery",
    "cage-card",
    "cage-page",
    "clin-case",
    "clin-cases",
    'clin-cases-by-session',
    "clin-case-observations",
    "device-location",
    "device-logs",
    "device-sessions",
    "devices-unaddressed",
    "locations-data",
    "location-assignments",
    "my-protocols",
    "new-user",
    "notes",
    "notifications",
    "protocol",
    "protocol-devices",
    "protocol-sessions",
    "sessions",
    "session-form-data",
    "session-logs",
    "staff",
    "staff-assignments",
    "users",
    "user-auth-request",
    "user-auth-req-form",
    "user-clin-cases",
    "user-details",
    "user-location-assignment",
    "user-settings"
  ],
  keepUnusedDataFor: 1800,
  endpoints: (builder) => ({
    /////////////////////// V1 //////////////////////////////
    auth: builder.mutation({
      query: (credentials) => ({
        url: '/user/login',
        method: 'POST',
        body: credentials,
      }),
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAuthToken(data.body.token));
        } catch (error) {
          dispatch(setAuthToken(null));
        }
      },
    }),

    /////////////////////// NOTIFICATIONS ///////////////////
    getAllNotificationsV1: builder.query({
      query: () => `api/v1/Notifications`,
      providesTags: ['notifications']
    }),
    getNotificationTypesV1: builder.query({
      query: () => `api/v1/Notifications/types`,
      providesTags: ['notification-types'],
      transformResponse: (data) => {
        let normalizedData = data.reduce((acc, obj) => {
          const { notificationTypeId, notificationName, notificationDescription, notificationLevel } = obj;
          if (!acc[notificationTypeId]) {
            acc[notificationTypeId] = { name: notificationName, description: notificationDescription, level: notificationLevel }
          }
          return acc;
        }, {})
        return normalizedData;
      }
    }),
    postClinCaseNotificationV1: builder.mutation({
      query: (notif) => ({
        url: `api/v1/Notifications`,
        method: 'POST',
        body: notif
      }),
      invalidatesTags: ['notifications']
    }),
    putClinCaseNotificationV1: builder.mutation({
      query: ({ caseId, active }) => ({
        url: `api/v1/Notifications/${caseId}/${active}`,
        method: 'PUT',
      }),
    }),

    /////////////////////// OBSERVATIONS ////////////////////
    postObservationV1: builder.mutation({
      query: (observation) => ({
        url: `api/v1/Observations`,
        method: 'POST',
        body: observation
      }),
      invalidatesTags: ['clin-case-observations'],
    }),

    /////////////////////// PROTOCOLS ///////////////////////
    getProtocolDevicesV1: builder.query({
      query: (protocolId) => `api/v1/Protocols/${protocolId}/devices`,
      providesTags: ['protocol-devices']
    }),

    /////////////////////// BUILDINGS ///////////////////////
    getAllBuildingsV1: builder.query({
      query: () => `api/v1/Buildings`,
      providesTags: ['all-buildings']
    }),
    getAllBuildingsAndRoomsV1: builder.query({
      query: () => `api/v1/Buildings/rooms`,
      providesTags: ['all-buildings-rooms'],
      transformResponse: (data) => {
        let normalizedData = data.reduce((acc, obj) => {
          const { building: { building, buildingId, buildingCode }, rooms } = obj;
          acc[building] = {
            id: buildingId,
            code: buildingCode,
            rooms: rooms
          };
          return acc;
        }, {});
        return normalizedData;
      }
    }),

    /////////////////////// SESSIONS ////////////////////////
    getSessionBySessionIdV1: builder.query({
      query: (sessionId) => `api/v1/Sessions/${sessionId}`,
      providesTags: ['session', 'cage-page']
    }),
    getDeviceBySessionIdV1: builder.query({
      query: (sessionId) => `api/v1/Sessions/${sessionId}/device`,
    }),
    getClinCasesBySessionIdV1: builder.query({
      query: (sessionId) => `api/v1/Sessions/${sessionId}/case`,
      providesTags: ['clin-cases-by-session']
    }),
    postSessionV1: builder.mutation({
      query: ({ sessionInfo, macAddress }) => ({
        url: `api/v1/Sessions/new/${macAddress}`,
        method: 'POST',
        body: sessionInfo
      }),
      invalidatesTags: ['sessions', 'all-devices', 'protocol-devices'],
    }),
    endSessionV1: builder.mutation({
      query: (sessionId) => ({
        url: `api/v1/Sessions/${sessionId}/end`,
        method: 'PATCH',
        body: sessionId
      }),
      invalidatesTags: ['sessions'],
    }),

    ///////////////////// SESSION LOGS //////////////////////
    postSessionLogV1: builder.mutation({
      query: (log) => ({
        url: `api/v1/Sessions/SessionLog`,
        method: 'POST',
        body: log
      }),
      invalidatesTags: ['session-logs', 'sessions', 'session', 'cage-page', 'protocol-devices'],
    }),
    getSessionLogsBySessionIdV1: builder.query({
      query: ({ sessionId, amount }) => `api/v1/Sessions/${sessionId}/sessionLogs/${amount}/location`,
      providesTags: ['session-logs', 'sessions', 'cage-page']
    }),

    /////////////////////// CLIN CASES //////////////////////
    getClinCaseInfoByIdV1: builder.query({
      query: (caseId) => `api/v1/ClinicalCase/${caseId}`
    }),
    getClinCaseForDeviceV1: builder.query({
      query: (sessionId) => `api/v1/ClinicalCase/${sessionId}/count`
    }),
    getClinCaseObservationsV1: builder.query({
      query: (caseId) => `api/v1/ClinicalCase/${caseId}/observations`,
      providesTags: ['clin-case-observations'],
      transformResponse: (data) => {
        if (!data) {
          return {};
        }
        let normalizedData = data.reduce((acc, obj, idx) => {
          acc[idx] = {
            observationId: obj.observation.observationId,
            authorId: obj.user.userId,
            authorFirstName: obj.user.firstName,
            authorLastName: obj.user.lastName,
            category: obj.observation.category,
            status: obj.observation.status,
            content: obj.observation.content,
            createdAt: obj.observation.createdAt,
          };
          return acc;
        }, {});
        return normalizedData;
      }
    }),
    postClinCaseV1: builder.mutation({
      query: (clinCase) => ({
        url: `api/v1/ClinicalCase`,
        method: 'POST',
        body: clinCase
      }),
      invalidatesTags: ['user-clin-cases']
    }),
    putClinCaseStatusV1: builder.mutation({
      query: ({ caseId, status }) => ({
        url: `api/v1/ClinicalCase/${caseId}/${status}`,
        method: 'PUT',
      }),
      invalidatesTags: ['user-clin-cases']
    }),
    putClinCaseNameV1: builder.mutation({
      query: ({ caseId, caseName }) => ({
        url: `api/v1/ClinicalCase/name/${caseId}/${caseName}`,
        method: 'PUT',
      }),
      invalidatesTags: ['user-clin-cases']
    }),
    /////////////////////// DEVICES /////////////////////////
    getAllDevicesV1: builder.query({
      query: () => `api/v1/Devices`,
      providesTags: ['all-devices']
    }),
    getDeviceByDeviceIdV1: builder.query({
      query: (deviceId) => `api/v1/Devices/${deviceId}`,
      providesTags: [``]
    }),
    getDevicesBatteryByProtocolV1: builder.query({
      query: (protocolId) => `api/v1/Devices/${protocolId}/battery`,
      providesTags: ['device-sessions', 'battery']
    }),
    getCagePageV1: builder.query({
      query: (macAddress) => `api/v1/Devices/${macAddress}/cage`,
      providesTags: ['sessions', 'cage-page']
    }),
    getDevicesByUserBuildingsV1: builder.query({
      query: (userID) => `api/v1/Devices/${userID}/buildings`,
      transformResponse: (data) => {
        if (!data || !Array.isArray(data.devices)) {
          return {};
        }

        let normalizedData = data.devices.reduce((acc, obj) => {
          if (!acc[obj.building]) acc[obj.building] = { bid: obj.buildingID, code: obj.buildingCode, rooms: [] };
          if (acc[obj.building].rooms.some(e => e.room === obj.room)) {
            acc[obj.building].rooms[acc[obj.building].rooms.findIndex(e => e.room === obj.room)].devices.push({ "cageCardId": obj.cageCardID, "naturalId": obj.naturalID, "MacAddress": obj.macAddress, "sessionId": obj.sessionID })
          } else acc[obj.building].rooms.push({ rid: obj.roomID, room: obj.room, devices: [{ "cageCardId": obj.cageCardID, "naturalId": obj.naturalID, "MacAddress": obj.macAddress, "sessionId": obj.sessionID }] });
          return acc;
        }, {});
        return normalizedData;
      }
    }),
    getUnaddressedDevicesV1: builder.query({
      query: () => `api/v1/Devices/unaddressed`,
      providesTags: [`devices-unaddressed`]
    }),
    activateDeviceLightV1: builder.query({
      query: (macAddress) => `api/v1/Devices/${macAddress}/light`,
      providesTags: ['device-light'],
      extraOptions: { maxRetries: 0 }
    }),

    /////////////////////// DEVICE LOGS /////////////////////
    getSingleDeviceLogsV1: builder.query({
      query: ({ deviceId, sessionId, reportType, start, end }) => `api/v1/Devices/logs/${deviceId}/${sessionId}/${reportType}/${start} - ${end}`,
      providesTags: ['device-logs'],
      transformResponse: (res) => {
        // console.log('RESPONSE: ', res)
        return res;
      }
    }),
    getEnvironmentalLogsByProtocolV1: builder.query({
      query: (request) => `api/v1/Devices/logs/protocol/${request.selectedProtocol}/${request.reportType}/${request.start} - ${request.end}`,
      providedTags: ['environment-logs', 'device-logs-by-protocol']
    }),
    getVocalizationCountByProtocolV1: builder.query({
      query: (request) => `api/v1/Devices/logs/vocalizations/${request.selectedProtocol}/${request.reportType}/${request.start} - ${request.end}`,
      providedTags: ['vocalization-counts', 'vocalization-count-by-protocol']
    }),

    /////////////////////// NOTES ///////////////////////////
    getNoteByNoteIdV1: builder.query({
      query: (noteId) => `api/v1/Notes/${noteId}`,
      providesTags: ['note']
    }),
    getNotesBySessionIdV1: builder.query({
      query: (sessionId) => `api/v1/Notes/session/${sessionId}`,
      providesTags: ['notes']
    }),
    postNoteV1: builder.mutation({
      query: (note) => ({
        url: `api/v1/Notes`,
        method: `POST`,
        body: note,
      }),
      invalidatesTags: ['notes']
    }),

    ///////////////////////// ROLES /////////////////////////
    getUsersByRoleNameV1: builder.query({
      query: (roleName) => `api/v1/Roles/${roleName}/users`,
      providesTags: ['users']
    }),
    /////////////////////// THRESHOLDS //////////////////////
    getAllThresholdLogsV1: builder.query({
      query: () => `api/v1/Thresholds`,
    }),
    getAllRecentThresholdLogsV1: builder.query({
      query: () => `api/v1/Thresholds/latest`,
      providesTags: ['room-thresholds']
    }),
    getThresholdByRoomIDV1: builder.query({
      query: (roomID) => `api/v1/Thresholds/latest/${roomID}`,
      providesTags: ['room-thresholds']
    }),
    // postObservationV1: builder.mutation({
    //   query: (observation) => ({
    //     url: `api/v1/Observations`,
    //     method: 'POST',
    //     body: observation
    //   }),
    //   invalidatesTags: ['clin-case-observations'],
    // }),
    postRoomThresholdLogV1: builder.mutation({
      query: (threshold) => ({
        url: `api/v1/Thresholds/new`,
        method: 'POST',
        body: threshold
      }),
      invalidatesTags: ['room-thresholds'],
    }),

    /////////////////////// USERS ///////////////////////////
    getUserByEmailV1: builder.query({
      query: (email) => ({
        url: `api/v1/Users/${email}/user`,
      }),
      providesTags: ['user'],
      transformErrorResponse: (res) => {
        if (res.status === 401) {
          console.log('Could not fetch user. You are unauthorized.')
          return res;
        } else if (res.status === 'FETCH_ERROR') {
          return { status: 500, message: 'Failed to fetch user from Server.' }
        } else return res;
      }
    }),
    updateUserV1: builder.mutation({
      query: (user) => ({
        url: `api/v1/Users`,
        method: 'PATCH',
        body: user
      }),
      invalidatesTags: ['users']
    }),
    getStaffByProtocolMembershipV1: builder.query({
      query: (userId) => `api/v1/Users/${userId}/staff`,
      providesTags: ['staff'],
      transformResponse: (res) => {
        let filteredData = res.reduce((acc, obj) => {
          let { userId } = obj;
          if (!acc[userId]) acc[userId] = obj;
          return acc;
        }, {})
        return Object.values(filteredData)
      }
    }),
    getUserProtocolsV1: builder.query({
      query: (userId) => `api/v1/Users/${userId}/protocols`,
      providesTags: ['my-protocols']
    }),
    getLocalStorageKeyV1: builder.query({
      query: () => ({
        url: `api/v1/Users/localStorageKey`,
        method: 'GET'
      }),
      transformResponse: (res) => {
        return res.message;
      }
    }),
    getClinCasesByUserIdV1: builder.query({
      query: (userId) => `api/v1/Users/${userId}/cases`,
      providesTags: ['user-clin-cases']
    }),
    getAssignedClinCasesByUserIdV1: builder.query({
      query: (userId) => `api/v1/ClinicalCase/${userId}/ClinCaseAssignments`,
      providesTags: ['user-clin-cases'],
    }),
    getUserRolesV1: builder.query({
      query: (userId) => `api/v1/Users/${userId}/roles`,
      providesTags: ['my-protocols']
    }),
    getUsersByVetStaffRoleV1: builder.query({
      query: () => ({
        url: `api/v1/Users/VetStaff`,
      }),
      providesTags: ['']
    }),
    getVetStaffUsersAndBuildingsV1: builder.query({
      query: () => ({
        url: `api/v1/Users/VetStaff/assignments`,
      }),
      providesTags: ['staff-assignments']
    }),
    postVetStaffUsersAndBuildingsV1: builder.mutation({
      query: (assignment) => ({
        url: `api/v1/Users/VetStaff/assignments/new`,
        method: 'POST',
        body: assignment
      }),
      invalidatesTags: ['staff-assignments']
    }),
    deleteVetStaffUsersAndBuildingsV1: builder.mutation({
      query: (assignment) => ({
        url: `api/v1/Users/VetStaff/assignments/delete`,
        method: 'DELETE',
        body: assignment
      }),
      invalidatesTags: ['staff-assignments']
    }),
    getUsersByVetRoleV1: builder.query({
      query: () => ({
        url: `api/v1/Users/Vets`,
      }),
      providesTags: ['']
    }),
    //////////////////// USER SETTINGS //////////////////////
    getUserSettings: builder.query({
      query: (userId) => ({
        url: `api/v1/UserSettings/${userId}`,
      }),
      providesTags: ['user-settings']
    }),
    postUserSettings: builder.mutation({
      query: (userSettings) => ({
        url: `api/v1/UserSettings`,
        method: 'POST',
        body: userSettings
      }),
      invalidatesTags: ['user-settings']
    }),
    patchUserSettings: builder.mutation({
      query: (userSettings) => ({
        url: `api/v1/UserSettings`,
        method: 'PATCH',
        body: userSettings
      }),
      invalidatesTags: ['user-settings']
    }),
    ///////////////// USERS AUTH REQUEST ////////////////////
    getUserAuthRequestByEmailV1: builder.query({
      query: (email) => `api/v1/UsersAuth/${email}`,
      providesTags: ['auth-requests']
    }),
    getAllUserAuthRequestsV1: builder.query({
      query: () => `api/v1/UsersAuth`,
      providesTags: ['auth-requests']
    }),
    authorizeNewUserV1: builder.mutation({
      query: (request) => ({
        url: `api/v1/UsersAuth`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['auth-requests']
    }),
    putUserAuthRequestV1: builder.mutation({
      query: (request) => ({
        url: `api/v1/UsersAuth`,
        method: 'PUT',
        body: request
      }),
      invalidatesTags: ['auth-requests']
    }),
    deleteUserAuthRequestV1: builder.mutation({
      query: (email) => ({
        url: `api/v1/UsersAuth/${email}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['auth-requests'],
      transformErrorResponse: (res) => {
        console.log('ERROR: ', res)
        return res;
      },
    }),

    //////////////////// CONSTANTS //////////////////////////
    postPageTrackerV1: builder.mutation({
      query: ({ userId, prettyURL, rawURL }) => ({
        url: `/api/v1/Constants`,
        body: { userId, prettyURL, rawURL },
        method: ['POST'],
      }),
    }),
  })
});

export const {
  /////////// V2 //////////////

  /////////// OBS /////////////
  usePostObservationV1Mutation,
  //////// NOTIFICATIONS ///////
  useGetAllNotificationsV1Query,
  useGetNotificationTypesV1Query,
  usePostClinCaseNotificationV1Mutation,
  usePutClinCaseNotificationV1Mutation,
  /////////// SESSIONS ////////
  useGetSessionBySessionIdV1Query,
  useLazyGetSessionBySessionIdV1Query,
  useLazyGetDeviceBySessionIdV1Query,
  useGetSessionLogsBySessionIdV1Query,
  useLazyGetSessionLogsBySessionIdV1Query,
  usePostSessionLogV1Mutation,
  usePostSessionV1Mutation,
  useEndSessionV1Mutation,
  /////////// DEVICES /////////
  useGetAllDevicesV1Query,
  useGetDevicesBatteryByProtocolV1Query,
  useGetCagePageV1Query,
  useGetSingleDeviceLogsV1Query,
  useGetDeviceByDeviceIdV1Query,
  useGetUnaddressedDevicesV1Query,
  /////////// Device Logs ///////
  useGetEnvironmentalLogsByProtocolV1Query,
  useGetVocalizationCountByProtocolV1Query,
  useGetDevicesByUserBuildingsV1Query,
  useActivateDeviceLightV1Query,
  useLazyActivateDeviceLightV1Query,
  /////////// BUILDINGS ///////
  useGetAllBuildingsV1Query,
  useGetAllBuildingsAndRoomsV1Query,
  /////////// ROLES ///////////
  useGetUsersByRoleNameV1Query,
  /////////// THRESHOLDS //////
  useGetAllThresholdLogsV1Query,
  useGetAllRecentThresholdLogsV1Query,
  useGetThresholdByRoomIDV1Query,
  useLazyGetThresholdByRoomIDV1Query,
  usePostRoomThresholdLogV1Mutation,
  /////////// USERS ///////////
  useGetUserProtocolsV1Query,
  useUpdateUserV1Mutation,
  useGetUserByEmailV1Query,
  useLazyGetUserByEmailV1Query,
  useGetUsersByVetStaffRoleV1Query,
  useGetVetStaffUsersAndBuildingsV1Query,
  usePostVetStaffUsersAndBuildingsV1Mutation,
  useDeleteVetStaffUsersAndBuildingsV1Mutation,
  useGetUsersByVetRoleV1Query,
  useGetUserRolesV1Query,
  useLazyGetUserRolesV1Query,
  useGetStaffByProtocolMembershipV1Query,
  useGetLocalStorageKeyV1Query,
  useLazyGetLocalStorageKeyV1Query,
  /////////// USER SETTINGS ///////////
  useGetUserSettingsQuery,
  useLazyGetUserSettingsQuery,
  usePostUserSettingsMutation,
  usePatchUserSettingsMutation,
  /////////// USERS AUTH ///////////
  useGetUserAuthRequestByEmailV1Query,
  useGetAllUserAuthRequestsV1Query,
  useAuthorizeNewUserV1Mutation,
  usePutUserAuthRequestV1Mutation,
  useDeleteUserAuthRequestV1Mutation,
  /////////// PROTOCOLS ///////////
  useGetProtocolDevicesV1Query,
  useLazyGetProtocolDevicesV1Query,
  /////////// CLIN CASES ///////////
  useGetClinCaseInfoByIdV1Query,
  useGetClinCasesBySessionIdV1Query,
  usePostClinCaseV1Mutation,
  useLazyGetClinCaseForDeviceV1Query,
  usePutClinCaseStatusV1Mutation,
  usePutClinCaseNameV1Mutation,
  useGetClinCasesByUserIdV1Query,
  useGetAssignedClinCasesByUserIdV1Query,
  useGetClinCaseObservationsV1Query,
  /////////// NOTES /////////////
  usePostNoteV1Mutation,
  useGetNotesBySessionIdV1Query,
  //////// CONSTANTS ///////////
  usePostPageTrackerV1Mutation,

} = penpalServerApi;

/*
api/users/:userid
api/users/all
api/users/active
api/protocols/:protocolid
api/notes/:protocol
*/
