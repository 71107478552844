import { BrowserRouter, Route, Routes } from "react-router-dom";
import BugForm from "../pages/BugForm";
import { useEffect } from "react";
import PageNotFound from "../components/global/PageNotFound";


const CareStaff = () => {

  useEffect(() => {
    if (localStorage.getItem('penpal path') === null) return;
    if (localStorage.getItem('penpal path') !== null && window.document.location.pathname === localStorage.getItem('penpal path')) {
      localStorage.removeItem('penpal path');
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/support/reportabug" element={<BugForm />} />
        <Route path="/*" element={<PageNotFound/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
};

export default CareStaff;