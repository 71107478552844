import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./auth/UserContext";
import { RedirectUserProvider } from "./components/context/RedirectContext";
import { Provider } from "react-redux";
import { store } from "./store/store.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.all';
import { SelectedNotifProvider } from "./components/context/NotifContext.js";
import { UserSettingsProvider } from "./components/context/UserSettingsContext.js";

const auth0Config = {
  "domain": "auth.penpal.day",
  "clientId": "qnSvkjt6xMSnUUnSAzO2QYhIwuXUL00i",
  "audience": "https://penpalserver.azurewebsites.net/api",
  "scope": "openid profile email offline_access"
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: auth0Config.scope,
        ...(auth0Config.audience ? { audience: auth0Config.audience } : null),
      }}
    >
      <Provider store={store}>
        <UserProvider>
          <UserSettingsProvider>
            <RedirectUserProvider>
              <SelectedNotifProvider>
                <I18nProvider local={"en"} messages={[messages]}>
                  <App />
                </I18nProvider>
              </SelectedNotifProvider>
            </RedirectUserProvider>
          </UserSettingsProvider>
        </UserProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
