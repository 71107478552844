import { useEffect, useState } from "react";
import LoadingScreen from "../components/global/LoadingScreen";
import { useUser } from "../auth/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useDeleteUserAuthRequestV1Mutation, usePutUserAuthRequestV1Mutation } from "../store/penpalServerAPI";

const CreateAccount = ({ isSuccess = false, isLoading = false }) => {
  // const user = useUser();
  const { user } = useAuth0();
  const [email, setEmail] = useState(user.email || "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [pi, setPi] = useState('');
  const [vet, setVet] = useState('');
  const [admin, setAdmin] = useState('');
  const [vetStaff, setVetStaff] = useState('');
  const [careStaff, setCareStaff] = useState('');
  const [errors, setErrors] = useState([]);

  const [
    putUserAuthRequest,
    {
      data: putReqData,
      isLoading: putReqIsLoading,
      isSuccess: putReqIsSuccess,
      isError: putReqIsError,
      error: putReqError
    }
  ] = usePutUserAuthRequestV1Mutation();

  const [
    deleteUserAuthRequest,
    {
      data: deleteReqData,
      isLoading: deleteReqIsLoading,
      isSuccess: deleteReqIsSuccess,
      isError: deleteReqIsError,
      error: deleteReqError
    }
  ] = useDeleteUserAuthRequestV1Mutation();

  useEffect(() => {
    if (user.Email) setEmail(user.Email.toLowerCase());
  }, [user]);

  useEffect(() => {
    setErrors([]);
  }, [email, firstName, lastName, phone])

  useEffect(() => {
  }, [isSuccess])

  function checkErrors(obj) {
    let bool = true;
    if (!obj.email) {
      setErrors([...errors, "Email Required"])
      bool = false;
    }
    if (!obj.firstName) {
      setErrors([...errors, "First Name Required"])
      bool = false;
    }
    if (!obj.lastName) {
      setErrors([...errors, "Last Name Required"])
      bool = false;
    }
    if (obj.phone.length < 10) {
      setErrors([...errors, "Phone Number Requires 10 Digits"])
      bool = false;
    }
    return bool;
  }

  const handleSubmit = async () => {
    const user = {
      email: email.toLowerCase(),
      firstName,
      lastName,
      phone,
      fax,
    };
    checkErrors(user)
    if (!errors.length) {
      await putUserAuthRequest(user);
    } else {
      console.log("Required fields missing.")
      console.log(putReqError)
    }
  };

  function updatePi() {
    if (pi) setPi('')
    else setPi('PI')
  }

  function updateVet() {
    if (vet) setVet('')
    else setVet('VET')
  }

  function updateAdmin() {
    if (admin) setAdmin('')
    else setAdmin('ADMIN')
  }

  function updateVetStaff() {
    if (vetStaff) setVetStaff('')
    else setVetStaff('VETSTAFF')
  }

  function updateCareStaff() {
    if (careStaff) setCareStaff('')
    else setCareStaff('CARESTAFF')
  }

  return (
    <div className="w-screen p-4 flex flex-col items-center justify-center">
      {isLoading ? <LoadingScreen /> : putReqIsLoading ? <LoadingScreen /> : (
        <>
          <div className="w-full flex justify-center">
            <p className="text-lg font-chaletNineteenSixty dark:text-white">Create your User Profile</p>
          </div>
          {errors ? (
            <div>
              {errors.map((msg) => {
                return (
                  <p className="text-red-600">{msg}</p>
                )
              })}
            </div>
          ) : null}
          <div className="w-11/12 max-w-[500px] min-h-[320px] bg-stone-50 shadow-lg rounded-xl px-2 flex flex-col mb-4 font-chaletNineteenSixty dark:bg-gray-700">
            <div className="">
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Email</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>First Name</label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Last Name</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Phone</label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.replace(/[^0-9.]/g, ''))}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Fax</label>
                <input
                  type=""
                  value={fax}
                  onChange={(e) => setFax(e.target.value.replace(/[^0-9.]/g, ''))}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
            </div>
            <div className="w-full flex justify-center pb-2">
              <button
                className="py-2 w-6/12 my-4 shadow-md bg-sunburst rounded-lg flex justify-center hover:cursor-pointer text-white text-lg"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateAccount;
